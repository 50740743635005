import * as React from 'react';
import { lcOnInit, assignEventHandlers, lcOnDestroy, getData, createWidget } from '@livechat/widget-core';

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

  return arr2;
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function useWidgetIsReady() {
  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      isReady = _React$useState2[0],
      setIsReady = _React$useState2[1];

  React.useEffect(function () {
    var onReady = function onReady() {
      return setIsReady(true);
    };

    var unsubscribeInit = lcOnInit(function () {
      assignEventHandlers('once', {
        onReady: onReady
      });
    });
    var unsubscribeDestroy = lcOnDestroy(function () {
      setIsReady(false);
    });
    return function () {
      assignEventHandlers('off', {
        onReady: onReady
      });
      unsubscribeInit();
      unsubscribeDestroy();
    };
  }, []);
  return isReady;
}

function useWidgetState() {
  var _React$useState = React.useState(null),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      widgetState = _React$useState2[0],
      setWidgetState = _React$useState2[1];

  React.useEffect(function () {
    var onReady = function onReady(_ref) {
      var state = _ref.state;
      return setWidgetState(state);
    };

    var onVisibilityChanged = function onVisibilityChanged(_ref2) {
      var visibility = _ref2.visibility;
      setWidgetState(function (prevState) {
        return prevState ? _objectSpread2(_objectSpread2({}, prevState), {}, {
          visibility: visibility
        }) : prevState;
      });
    };

    var onAvailabilityChanged = function onAvailabilityChanged(_ref3) {
      var availability = _ref3.availability;
      setWidgetState(function (prevState) {
        return prevState ? _objectSpread2(_objectSpread2({}, prevState), {}, {
          availability: availability
        }) : prevState;
      });
    };

    var unsubscribeInit = lcOnInit(function () {
      assignEventHandlers('once', {
        onReady: onReady
      });
      assignEventHandlers('on', {
        onVisibilityChanged: onVisibilityChanged,
        onAvailabilityChanged: onAvailabilityChanged
      });
    });
    var unsubscribeDestroy = lcOnDestroy(function () {
      setWidgetState(null);
    });
    return function () {
      assignEventHandlers('off', {
        onReady: onReady,
        onVisibilityChanged: onVisibilityChanged,
        onAvailabilityChanged: onAvailabilityChanged
      });
      unsubscribeInit();
      unsubscribeDestroy();
    };
  }, []);
  return widgetState;
}

function useWidgetCustomerData() {
  var _React$useState = React.useState(null),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      customerData = _React$useState2[0],
      setCustomerData = _React$useState2[1];

  React.useEffect(function () {
    var onReady = function onReady(_ref) {
      var customerData = _ref.customerData;
      return setCustomerData(customerData);
    };

    var onCustomerStatusChanged = function onCustomerStatusChanged() {
      return setCustomerData(getData('customer'));
    };

    var unsubscribeInit = lcOnInit(function () {
      assignEventHandlers('once', {
        onReady: onReady
      });
      assignEventHandlers('on', {
        onCustomerStatusChanged: onCustomerStatusChanged
      });
    });
    var unsubscribeDestroy = lcOnDestroy(function () {
      setCustomerData(null);
    });
    return function () {
      assignEventHandlers('off', {
        onReady: onReady,
        onCustomerStatusChanged: onCustomerStatusChanged
      });
      unsubscribeInit();
      unsubscribeDestroy();
    };
  }, []);
  return customerData;
}

function useWidgetChatData() {
  var customerData = useWidgetCustomerData();
  var status = customerData === null || customerData === void 0 ? void 0 : customerData.status;

  var _React$useState = React.useState(null),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      chatData = _React$useState2[0],
      setChatData = _React$useState2[1];

  React.useEffect(function () {
    if (status === 'chatting') {
      setChatData(getData('chat'));
    } else {
      setChatData(null);
    }
  }, [status]);
  return chatData;
}

function useWidgetGreeting() {
  var _React$useState = React.useState(null),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      greeting = _React$useState2[0],
      setGreeting = _React$useState2[1];

  React.useEffect(function () {
    var onGreetingDisplayed = function onGreetingDisplayed(greeting) {
      return setGreeting(greeting);
    };

    var onGreetingHidden = function onGreetingHidden() {
      return setGreeting(null);
    };

    var unsubscribeInit = lcOnInit(function () {
      assignEventHandlers('on', {
        onGreetingDisplayed: onGreetingDisplayed,
        onGreetingHidden: onGreetingHidden
      });
    });
    var unsubscribeDestroy = lcOnDestroy(function () {
      setGreeting(null);
    });
    return function () {
      assignEventHandlers('off', {
        onGreetingDisplayed: onGreetingDisplayed,
        onGreetingHidden: onGreetingHidden
      });
      unsubscribeInit();
      unsubscribeDestroy();
    };
  }, []);
  return greeting;
}

function LiveChatWidget(props) {
  var widgetRef = React.useRef(null);
  React.useEffect(function () {
    widgetRef.current = createWidget(props);
    window.__lc.integration_name = "@livechat/widget-react";
    widgetRef.current.init();
    return function () {
      var _widgetRef$current;

      (_widgetRef$current = widgetRef.current) === null || _widgetRef$current === void 0 ? void 0 : _widgetRef$current.destroy();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.license, props.group, props.chatBetweenGroups]);
  React.useEffect(function () {
    var _widgetRef$current2;

    (_widgetRef$current2 = widgetRef.current) === null || _widgetRef$current2 === void 0 ? void 0 : _widgetRef$current2.updateVisibility(props.visibility);
  }, [props.visibility]);
  React.useEffect(function () {
    var _widgetRef$current3;

    (_widgetRef$current3 = widgetRef.current) === null || _widgetRef$current3 === void 0 ? void 0 : _widgetRef$current3.updateSessionVariables(props.sessionVariables);
  }, [props.sessionVariables]);
  React.useEffect(function () {
    var _widgetRef$current4;

    (_widgetRef$current4 = widgetRef.current) === null || _widgetRef$current4 === void 0 ? void 0 : _widgetRef$current4.updateCustomerData({
      name: props.customerName,
      email: props.customerEmail
    });
  }, [props.customerName, props.customerEmail]);
  React.useEffect(function () {
    var _widgetRef$current5;

    (_widgetRef$current5 = widgetRef.current) === null || _widgetRef$current5 === void 0 ? void 0 : _widgetRef$current5.updateEventHandlers(_objectSpread2(_objectSpread2(_objectSpread2(_objectSpread2(_objectSpread2(_objectSpread2(_objectSpread2(_objectSpread2(_objectSpread2(_objectSpread2({}, props.onReady && {
      onReady: props.onReady
    }), props.onNewEvent && {
      onNewEvent: props.onNewEvent
    }), props.onFormSubmitted && {
      onFormSubmitted: props.onFormSubmitted
    }), props.onGreetingHidden && {
      onGreetingHidden: props.onGreetingHidden
    }), props.onRatingSubmitted && {
      onRatingSubmitted: props.onRatingSubmitted
    }), props.onGreetingDisplayed && {
      onGreetingDisplayed: props.onGreetingDisplayed
    }), props.onVisibilityChanged && {
      onVisibilityChanged: props.onVisibilityChanged
    }), props.onAvailabilityChanged && {
      onAvailabilityChanged: props.onAvailabilityChanged
    }), props.onCustomerStatusChanged && {
      onCustomerStatusChanged: props.onCustomerStatusChanged
    }), props.onRichMessageButtonClicked && {
      onRichMessageButtonClicked: props.onRichMessageButtonClicked
    }));
  }, [props.onReady, props.onNewEvent, props.onFormSubmitted, props.onGreetingHidden, props.onRatingSubmitted, props.onGreetingDisplayed, props.onVisibilityChanged, props.onAvailabilityChanged, props.onCustomerStatusChanged, props.onRichMessageButtonClicked]);
  return null;
}

export { LiveChatWidget, useWidgetChatData, useWidgetCustomerData, useWidgetGreeting, useWidgetIsReady, useWidgetState };
