import mitt from 'mitt';

function getData(entity) {
  if (entity === 'state') {
    return window.LiveChatWidget.get('state');
  }

  if (entity === 'chat') {
    return window.LiveChatWidget.get('chat_data');
  }

  if (entity === 'customer') {
    return window.LiveChatWidget.get('customer_data');
  }

  return null;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

  return arr2;
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

/* eslint-disable prefer-rest-params, @typescript-eslint/no-explicit-any, @typescript-eslint/ban-ts-comment, @typescript-eslint/unbound-method */
var scriptRef = {
  current: null
};
function createJSApi() {
  var _scriptRef$current;

  var slice = Array.prototype.slice;
  /* istanbul ignore next */

  function handle(args) {
    // @ts-ignore
    return api._h ? api._h.apply(null, args) : api._q.push(args);
  }
  /* istanbul ignore next */


  var api = {
    _q: [],
    _h: null,
    _v: '2.0',
    on: function on() {
      handle(['on', slice.call(arguments)]);
    },
    once: function once() {
      handle(['once', slice.call(arguments)]);
    },
    off: function off() {
      handle(['off', slice.call(arguments)]);
    },
    get: function get() {
      if (!api._h) {
        throw new Error("[LiveChatWidget] You can't use getters before load.");
      }

      return handle(['get', slice.call(arguments)]);
    },
    call: function call() {
      handle(['call', slice.call(arguments)]);
    },
    init: function init() {
      var script = document.createElement('script');
      script.async = true;
      script.type = 'text/javascript';
      script.src = 'https://cdn.livechatinc.com/tracking.js';
      document.head.appendChild(script);
      scriptRef.current = script;
    }
  };
  (_scriptRef$current = scriptRef.current) === null || _scriptRef$current === void 0 ? void 0 : _scriptRef$current.remove();
  window.LiveChatWidget = window.LiveChatWidget || api;
  return scriptRef;
}

function assignConfiguration(_ref) {
  var license = _ref.license,
      group = _ref.group,
      chatBetweenGroups = _ref.chatBetweenGroups,
      sessionVariables = _ref.sessionVariables;
  window.__lc = window.__lc || {};

  if (typeof license === 'string') {
    window.__lc.license = Number(license);
  } else {
    throw new Error('[LiveChatWidget] The license property is required for initialization');
  }

  if (typeof group !== 'undefined') {
    window.__lc.group = Number(group);
  }

  if (typeof chatBetweenGroups !== 'undefined') {
    window.__lc.chat_between_groups = Boolean(chatBetweenGroups);
  }

  if (sessionVariables instanceof Object) {
    window.__lc.params = Object.entries(sessionVariables).map(function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
          name = _ref3[0],
          value = _ref3[1];

      return {
        name: name,
        value: value
      };
    });
  }
}

function assignEventHandlers(method, eventHandlers) {
  if (method === 'off' && typeof window.LiveChatWidget === 'undefined') {
    return;
  }

  if (typeof eventHandlers.onReady === 'function') {
    window.LiveChatWidget[method]('ready', eventHandlers.onReady);
  }

  if (typeof eventHandlers.onAvailabilityChanged === 'function') {
    window.LiveChatWidget[method]('availability_changed', eventHandlers.onAvailabilityChanged);
  }

  if (typeof eventHandlers.onVisibilityChanged === 'function') {
    window.LiveChatWidget[method]('visibility_changed', eventHandlers.onVisibilityChanged);
  }

  if (typeof eventHandlers.onCustomerStatusChanged === 'function') {
    window.LiveChatWidget[method]('customer_status_changed', eventHandlers.onCustomerStatusChanged);
  }

  if (typeof eventHandlers.onNewEvent === 'function') {
    window.LiveChatWidget[method]('new_event', eventHandlers.onNewEvent);
  }

  if (typeof eventHandlers.onFormSubmitted === 'function') {
    window.LiveChatWidget[method]('form_submitted', eventHandlers.onFormSubmitted);
  }

  if (typeof eventHandlers.onRatingSubmitted === 'function') {
    window.LiveChatWidget[method]('rating_submitted', eventHandlers.onRatingSubmitted);
  }

  if (typeof eventHandlers.onGreetingDisplayed === 'function') {
    window.LiveChatWidget[method]('greeting_displayed', eventHandlers.onGreetingDisplayed);
  }

  if (typeof eventHandlers.onGreetingHidden === 'function') {
    window.LiveChatWidget[method]('greeting_hidden', eventHandlers.onGreetingHidden);
  }

  if (typeof eventHandlers.onRichMessageButtonClicked === 'function') {
    window.LiveChatWidget[method]('rich_message_button_clicked', eventHandlers.onRichMessageButtonClicked);
  }
}

function assignCustomerData(customerData) {
  if (typeof customerData.name === 'string') {
    window.LiveChatWidget.call('set_customer_name', customerData.name);
  }

  if (typeof customerData.email === 'string') {
    window.LiveChatWidget.call('set_customer_email', customerData.email);
  }
}

function assignVisibility(visibility) {
  if (visibility === 'hidden') {
    window.LiveChatWidget.call('hide');
  }

  if (visibility === 'maximized') {
    window.LiveChatWidget.call('maximize');
  }

  if (visibility === 'minimized') {
    window.LiveChatWidget.call('minimize');
  }
}

var emitter = mitt();
var lifecycleEmit = emitter.emit.bind(null);
function lcOnInit(callback) {
  emitter.on('init', callback);

  if ('LiveChatWidget' in window) {
    callback();
  }

  return function () {
    emitter.off('init', callback);
  };
}
function lcOnDestroy(callback) {
  emitter.on('destroy', callback);
  return function () {
    emitter.off('destroy', callback);
  };
}

function createWidget(config) {
  var state = {
    isLoading: false,
    desiredState: 'unknown',
    currentEventHandlers: {
      onReady: config.onReady,
      onNewEvent: config.onNewEvent,
      onFormSubmitted: config.onFormSubmitted,
      onGreetingHidden: config.onGreetingHidden,
      onRatingSubmitted: config.onRatingSubmitted,
      onGreetingDisplayed: config.onGreetingDisplayed,
      onVisibilityChanged: config.onVisibilityChanged,
      onAvailabilityChanged: config.onAvailabilityChanged,
      onCustomerStatusChanged: config.onCustomerStatusChanged,
      onRichMessageButtonClicked: config.onRichMessageButtonClicked
    }
  };
  var scriptRef = createJSApi();
  assignConfiguration(config);
  assignVisibility(config.visibility);
  assignEventHandlers('on', state.currentEventHandlers);
  assignCustomerData({
    name: config.customerName,
    email: config.customerEmail
  });
  window.__lc.integration_name = "@livechat/widget-core";
  return {
    init: function init() {
      var _this = this;

      state.desiredState = 'loaded';

      if (state.isLoading) {
        return;
      }

      window.LC_API = window.LC_API || {};

      window.LC_API.on_after_load = function () {
        state.isLoading = false;

        if (state.desiredState === 'destroyed') {
          _this.destroy();
        }

        state.desiredState = 'unknown';
      };

      lifecycleEmit('init');
      state.isLoading = true;
      window.LiveChatWidget.init();
    },
    destroy: function destroy() {
      var _scriptRef$current;

      state.desiredState = 'destroyed';

      if (state.isLoading) {
        return;
      }

      lifecycleEmit('destroy');
      (_scriptRef$current = scriptRef.current) === null || _scriptRef$current === void 0 ? void 0 : _scriptRef$current.remove();
      window.LiveChatWidget.call('destroy');
    },
    updateVisibility: function updateVisibility(visibility) {
      assignVisibility(visibility);
    },
    updateEventHandlers: function updateEventHandlers(eventHabndlers) {
      assignEventHandlers('off', state.currentEventHandlers);
      assignEventHandlers('on', eventHabndlers);
      state.currentEventHandlers = _objectSpread2({}, eventHabndlers);
    },
    updateSessionVariables: function updateSessionVariables(sessionVariables) {
      if (sessionVariables) {
        window.LiveChatWidget.call('update_session_variables', sessionVariables);
      }
    },
    hideGreeting: function hideGreeting() {
      window.LiveChatWidget.call('hide_greeting');
    },
    updateCustomerData: function updateCustomerData(customerData) {
      assignCustomerData(customerData);
    }
  };
}

export { assignConfiguration, assignCustomerData, assignEventHandlers, assignVisibility, createWidget, getData, lcOnDestroy, lcOnInit };
